body {
  color: #363a3e;
  font-family: "Roboto" !important;
  background-color: #f8f9fb !important;
  overflow: auto !important;
}

html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.only-mobile {
  display: none;
}

button:focus {
  text-decoration: none;
}

.alert-warning-no-data {
  font-style: italic;
  text-align: center;
}

a {
  color: inherit;
}

.MuiMenuItem-root a {
  color: inherit;
}

a:hover {
  text-decoration: none !important;
}

button {
  text-transform: unset !important;
  font-weight: 400 !important;
}

button:focus {
  outline: 0px !important;
}

legend {
  width: 0 !important;
}

.custom-mask {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #363a3e;
  background-color: #f6f6f6;
  background-clip: padding-box;
  border: 1px solid #f6f6f6;
  border-radius: 16px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

::-webkit-scrollbar {
  width: 6px;
  height: 10px;
  margin: 20px auto;
  /* padding-top:25px; */
  /* border-radius:150px; */
}

/* Track */
::-webkit-scrollbar-track {
  background: unset;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f8f9fb;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

.custom-mask:focus {
  outline: 0px;
}

.custom-mask-disable {
  background-color: #e9ecef;
  opacity: 1;
}

.buttongroup {
  display: flex;
  border-radius: 16px;
  border: 1px solid #bf0d3e;
}

.buttongroup>.rbtn {
  width: 50%;
  padding: 9px 0;
  background-color: #b1b1b1;
  border: 0px;
  border-radius: 0;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}

.buttongroup>.rbtn:first-child {
  border-radius: 16px 0 0 16px;
  border-right: 0px;
}

.buttongroup>.rbtn:last-child {
  border-radius: 0 16px 16px 0;
  border-left: 0px;
}

/*TARJETA DE CREDITO*/
.creditCard {
  padding: 0.5em;
  color: #fff;
  border-radius: 8px;
  opacity: 0.4;
  cursor: pointer;
  background: -webkit-linear-gradient(left, #a6b1b5 60%, #929292 90%);
  background: -o-linear-gradient(left, #a6b1b5 60%, #929292 90%);
  background: linear-gradient(to right, #a6b1b5 60%, #929292 90%);
  max-height: 8.2em;
}

.creditCard-active {
  opacity: 1 !important;
}

.BCP {
  background: -webkit-linear-gradient(left, #4092b5 60%, #3580aa 90%);
  background: -o-linear-gradient(left, #4092b5 60%, #3580aa 90%);
  background: linear-gradient(to right, #4092b5 60%, #3580aa 90%);
}

.INTERBANK {
  background: linear-gradient(to right, #40b569 60%, #35aa64 90%);
  background: linear-gradient(to right, #40b569 60%, #35aa64 90%);
  background: linear-gradient(to right, #40b569 60%, #35aa64 90%);
}

.BBVA {
  background: -webkit-linear-gradient(circle, #63d0ff 60%, #3580aa 90%);
  background: -o-linear-gradient(circle, #63d0ff 60%, #3580aa 90%);
  background: radial-gradient(circle, #63d0ff 0%, #3580aa 100%);
}

.SCOTIABANK {
  background: linear-gradient(to right, #e04040 60%, #e83d3d 90%);
  background: linear-gradient(to right, #e04040 60%, #e83d3d 90%);
  background: linear-gradient(to right, #e04040 60%, #e83d3d 90%);
}

.banco {
  text-align: right;
}

.creditCard .chipLogo {
  grid-area: chip;
  overflow: hidden;
  width: 38px;
  height: 30px;
  justify-self: left;
  margin-top: 10px;
  margin-bottom: 10px;
}

.creditCard-add {
  opacity: 0.7;
  font-weight: 500;
}

.text_tarjeta {
  font-size: 12px;
  margin-bottom: 0;
}

/*FIN TARJETA DE CREDITO*/
.title-mobile {
  display: none;
}

::-webkit-scrollbar {
  background-color: #fff;
  width: 12px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #fff;
}

::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 3px solid #fff;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 3px solid #f4f4f4;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

.alert-danger {
  background-color: #ffefed !important;
  border: none !important;
}

.table thead th {
  font-weight: 600;
  font-size: 16px;
}

.table tbody td {
  font-size: 14px;
}

@media only screen and (max-width: 900px) {
  body {
    background-color: white !important;
  }
}

@media only screen and (max-width: 600px) {
  .title-mobile {
    display: block;
    font-size: 22px;
  }

  .only-mobile {
    display: inline-block;
    font-weight: 700;
    max-width: 170px;
    width: 100%;
    margin-right: 5px;
  }

  .only-desktop {
    display: none !important;
  }

  .content-card-number>p {
    margin-bottom: 0;
  }

  .align-movil {
    justify-content: flex-end !important;
  }
}

.input-group .input-group-text {
  background-color: #f6f6f6;
  border: unset;
  border-radius: 16px;
}

.tooltip-custom .tooltiptext {
  background-color: #2f6d95;
}

.MuiButton-root.tooltip-rent {
  min-width: 20px;
  padding: 0 2px;
}

.MuiTooltip-popper[data-popper-placement*="bottom"] .MuiTooltip-tooltip {
  margin-top: 16px;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  width: 210px;
  font-size: 11px;
  line-height: 13px;
}

@media only screen and (max-width: 600px) {
  .collapse:not(.show) {
    display: none !important;
  }
}